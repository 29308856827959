#tt-pageContent
  .shopify-section
  .container-indent:not(.nomargin):not([class^="tt-offset"]) {
  margin-top: 61px !important;
}
@media (max-width: 575px) {
  #tt-pageContent
    .shopify-section
    .container-indent:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: -11px !important;
  }
}
@media (max-width: 1024px) {
  #tt-pageContent
    .shopify-section
    .container-indent:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: -6px !important;
  }
}

@media (max-width: 512px) {
  .tt-promo-fullwidth > img {
    min-height: 300px !important;
  }
}
